import React from 'react';
import {Helmet} from "react-helmet";
import LayoutTmpl from "../components/layout";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Links from '@material-ui/core/Link';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));

const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));

const Offer = () => {

    return (
        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>Пользовательское соглашение</title>
                <meta name="description" content="Пользовательское соглашение"/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        <StyledPaper>
                            <Typography gutterBottom component="h1" variant="h5" color="textPrimary">
                                Пользовательское соглашение
                            </Typography>

                            <Typography variant="body2" gutterBottom>Администратор
                                сайта <Links href="https://ocenivay.com">ocenivay.com</Links> («Администратор»)
                                предоставляет
                                неограниченному кругу лиц право использования сервисов сайта <Links
                                    href="https://ocenivay.com">ocenivay.com</Links> на условиях простой
                                неисключительной
                                лицензии и в соответствии с положениями настоящего Пользовательского соглашения,
                                являющегося публичной офертой.</Typography><br/>

                            <Typography variant="body2" gutterBottom><b>1. ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕМ
                                СОГЛАШЕНИИ</b></Typography>
                            <Typography variant="body2" gutterBottom>1.1. "Сайт" – информационный ресурс, расположенный
                                в
                                информационно-телекоммуникационной сети общего пользования «Интернет» по
                                адресу с
                                доменным именем <Links href="https://ocenivay.com">ocenivay.com</Links>, включая как
                                существующие на момент принятия условий настоящего соглашения, так и
                                вводимые в
                                действие в будущем в течение всего срока существования ресурса, поддомены
                                (субдомены) указанного домена второго уровня, а также все связанные с
                                ресурсом
                                программы для ЭВМ, обеспечивающие его функционирование.</Typography>
                            <Typography variant="body2" gutterBottom>1.2. "Администратор" - физическое лицо, являющееся
                                администратором доменного имени
                                второго уровня <Links href="https://ocenivay.com">ocenivay.com</Links></Typography>
                            <Typography variant="body2" gutterBottom>1.3. "Пользователь" – любое лицо, использующее
                                сервисы и функционал Сайта.
                                Пользователь вправе пройти процедуру Регистрации для получения дополнительных
                                возможностей использования функционала Сайта таких но не ограничиваясь как написание
                                отзывов, комментариев к ним, голосование за отзывы, размещение объектов.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.4. "Автор" — Пользователь, прошедший процедуру
                                Регистрации, размещающий Отзывы на
                                Сайте, и предоставляющий Администратору исключительную лицензию на весь срок
                                действия авторских прав без каких-либо ограничений в отношении написанных отзывов.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.5. "Отзыв" — создаваемое творческим трудом Автора
                                произведение, содержащее мнение
                                и отражение личного опыта Автора в результате использования того или иного продукта
                                или услуги.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.6. "Пользовательское соглашение" – настоящее
                                соглашение, являющееся публичной
                                офертой, заключаемое при регистрации на Сайте между Пользователем и Администратором.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.7. "Регистрация" — процесс предоставления
                                Пользователем идентифицирующей его
                                информации и обработки этой информации сервисами Сайта, в результате которой на
                                Сайте создаётся Учётная запись (аккаунт) Пользователя.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.8. "Учётная запись" ("аккаунт") — уникальная
                                совокупность регистрационных данных
                                Пользователя, хранимая на Сайте, являющаяся средством аутентификации Пользователя,
                                необходимая для возможности использования Пользователем функционала Сайта.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.9. "Логин" — уникальное имя Учётной записи,
                                определяемое Пользователем. В качестве
                                Логина также может использоваться адрес электронной почты (e-mail) Пользователя.
                            </Typography>
                            <Typography variant="body2" gutterBottom>1.10. "Пароль" - секретный символов, определяемых
                                Пользователем, служащий для
                                возможности индивидуального использования Пользователем Учётной записи на Сайте.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>2. ОСНОВНЫЕ ПОЛОЖЕНИЯ</b></Typography>
                            <Typography variant="body2" gutterBottom>2.1. Администратор предлагает Пользователю
                                использовать Сайт на условиях, изложенных
                                в настоящем Пользовательском соглашении.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.2. Пользовательское соглашение вступает в силу с
                                момента начала использования
                                Пользователем любой функциональной возможности, предоставляемой Сайтом и действует
                                бессрочно.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.3. Пользовательское соглашение может быть
                                изменено Администратором без какого-либо
                                специального уведомления Пользователей в любое время в одностороннем порядке.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.4. Действующая редакция настоящего
                                Пользовательского соглашения находится на Сайте
                                по постоянному адресу <Links
                                    href="https://ocenivay.com/offer">https://ocenivay.com/offer</Links>
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.5. Новая редакция Пользовательского соглашения
                                вступает в силу и применяется после
                                её размещения по адресу, указанному в п. 2.4 настоящего соглашения, если иное не
                                предусмотрено положениями новой редакции.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.6. Пользователь считается полностью и
                                безоговорочно принявшим условия
                                Пользовательского соглашения в случае совершение любого из указанных действий
                                (конклюдентные действия):
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.6.1. Использование Сайта или его отдельных
                                функциональных элементов любыми
                                способами, в том числе способами, предусмотренными действующим законодательством
                                Российской Федерации.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.6.2 Прохождение процедуры Регистрации на Сайте в
                                качестве Пользователя.</Typography>
                            <Typography variant="body2" gutterBottom>2.7. Принятие условий Пользовательского соглашения
                                возможно лишь в полном объёме,
                                без каких-либо оговорок и исключений.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.8. Пользователь, не согласный с условиями
                                Пользовательского соглашения, не вправе
                                использовать Сайт.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.9. В случае, если в Пользовательское соглашение
                                были внесены изменения, с
                                принятием которых Пользователь не согласен, Пользователь не вправе далее
                                использовать Сайт, и обязуется прекратить любое дальнейшее использование Сайта.
                            </Typography>
                            <Typography variant="body2" gutterBottom>2.10. К настоящему Пользовательскому соглашению и
                                возникающим в результате
                                использования Сайта отношениями между Администратором и Пользователем применяется
                                право Российской Федерации.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>3. РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ</b></Typography>
                            <Typography variant="body2" gutterBottom>3.1. Для реализации возможности использования Сайта
                                в полном объёме, требуется
                                регистрация Учётной записи (аккаунта) Пользователя.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.2. Для целей регистрации Пользователь
                                обязан:</Typography>
                            <Typography variant="body2" gutterBottom>3.2.1. Предоставлять полную, достаточную и
                                достоверную информацию о себе по
                                вопросам, указанным в регистрационной форме. Поля, обязательные для заполнения,
                                отмечены специальным образом.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.2.2. Поддерживать регистрационные данные в
                                актуальном состоянии.</Typography>
                            <Typography variant="body2" gutterBottom>3.3. Пользователю запрещается:</Typography>
                            <Typography variant="body2" gutterBottom>3.3.1. Предоставлять недостоверные регистрационные
                                данные.</Typography>
                            <Typography variant="body2" gutterBottom>3.3.2. Регистрировать Учётную запись (аккаунт) от
                                имени юридического лица за
                                исключением случаев, когда лицо официально уполномочило совершить указанные действия
                                от его имени.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.3.3. Регистрировать для себя более одной Учётной
                                записи (аккаунта).</Typography>
                            <Typography variant="body2" gutterBottom>3.4. Администратор вправе отказать в регистрации
                                без объяснения причин или
                                удалить/заблокировать Учётную запись Пользователя в случаях несоблюдения условий
                                настоящего соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.5. Пользователь самостоятельно определяет Логин и
                                Пароль, при этом Администратор
                                вправе установить ограничения на создание Учётной записи (аккаунта) с использованием
                                определённых логинов, а также требования к содержанию пароля (минимальное количество
                                символов, безопасность).
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.6. Пользователь самостоятельно несёт
                                ответственность за сохранность своего Логина
                                и Пароля и не вправе передавать эти данные третьим лицам.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.7. В случае обнаружения Пользователем активности
                                третьих лиц с использованием
                                Логина и Пароля Пользователя или подозрении о компрометации Логина и Пароля
                                Пользователя, он обязан незамедлительно сообщить об этом Администратору посредством
                                отправки сообщения по URL адресу <Links
                                    href="https://ocenivay.com/feedback">http://ocenivay.com/feedback</Links></Typography>
                            <Typography variant="body2" gutterBottom>3.8. Действия, совершённые с использованием Логина
                                и Пароля Пользователя
                                предполагаются совершёнными от имени Пользователя пока не доказано обратное, за
                                исключением случаев, когда Пользователь уведомил Администратора Сайта об утрате
                                конфиденциальности Логина и Пароля, согласно пункту 3.7 настоящего соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>3.9. Пользователь вправе запросить удаление Учётной
                                записи только в случае, когда от
                                имени данной Учетной записи на Сайте не было зафиксировано добавление отзывов,
                                комментариев, голосования, переписки, загрузки материалов Сайта.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>4. ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</b></Typography>
                            <Typography variant="body2" gutterBottom>4.1. Соблюдать положения действующего
                                законодательства Российской Федерации, условия
                                Пользовательского соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.2. Предоставлять полную, достаточную и
                                достоверную информацию о себе. Поддерживать
                                актуальность указанной информации.
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.3. Незамедлительно информировать Администратора о
                                несанкционированных действиях
                                третьих лиц, указанных в п. 3.8 настоящего соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.4. Воздерживаться от публикации информации или
                                иных материалов, которые:</Typography>
                            <Typography variant="body2" gutterBottom>4.4.1. Порочат честь, достоинство или деловую
                                репутацию других пользователей или
                                третьих лиц;
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.4.2. Содержат призывы к насилию, пропагандирует
                                дискриминацию людей по расовому,
                                этническому, половому, религиозному, социальному признакам;
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.4.3. Нарушают интеллектуальные права
                                Пользователей или третьих лиц;</Typography>
                            <Typography variant="body2" gutterBottom>4.4.4. Любым иным образом нарушают законодательство
                                Российской Федерации или нормы
                                международного права.
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.5. Воздерживаться от размещения ссылок на ресурсы
                                в
                                информационно-телекоммуникационных сетях, содержащие информацию или материалы,
                                указанные в п. 4.4 настоящего соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.6. При обнаружении информации или материалов,
                                указанных в п. 4.4 настоящего
                                соглашения, незамедлительно уведомить об этом Администратора посредством отправки
                                сообщения по URL адресу <Links
                                    href="https://ocenivay.com/feedback">https://ocenivay.com/feedback</Links>
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.7. Предоставить ответ и необходимые материалы в
                                течение 30 дней на запрос
                                Администратора для проверки достоверности опубликованной информации и соблюдения
                                условий настоящего соглашения посредством отправки сообщения по URL адресу <Links
                                    href="https://ocenivay.com/feedback">https://ocenivay.com/feedback</Links>
                            </Typography>
                            <Typography variant="body2" gutterBottom>4.8. Устранить соответствующие нарушения в течение
                                30 дней с момента поступления
                                требования и уведомить об этом Администратора посредством отправки сообщения по URL
                                адресу <Links href="https://ocenivay.com/feedback">https://ocenivay.com/feedback</Links>
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>5. ИНТЕЛЛЕКТУАЛЬНЫЕ ПРАВА</b></Typography>
                            <Typography variant="body2" gutterBottom>5.1. Пользователь не вправе размещать каким-либо
                                образом на Сайте материалы,
                                содержащие неправомерно используемые результаты интеллектуальной деятельности или
                                средства индивидуализации третьих лиц.
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.2. При опубликовании на Сайте результатов
                                интеллектуальной деятельности,
                                правообладателем которых является Пользователь, включая Отзывы, он предоставляет
                                Администратору исключительную лицензию на их использование путём воспроизведения
                                (копирования), переработку (изменение, в т.ч. путём модерации), доведение до
                                всеобщего сведения и использование иными способами для целей работы Сайта на весь
                                срок действия исключительного права на результат интеллектуальной деятельности без
                                каких-либо территориальных или иных пространственных ограничений.
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.3. Указанные права предоставляются Пользователем
                                на безвозмездной основе.</Typography>
                            <Typography variant="body2" gutterBottom>5.4. Удаление или блокировка учётной записи
                                Пользователя не означает прекращения
                                действия исключительной лицензии, предоставляемой Пользователем Администратору.
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.5. Администратор вправе блокировать, перемещать и
                                удалять без предупреждения:</Typography>
                            <Typography variant="body2" gutterBottom>5.5.1. Отзывы, сообщения и иные материалы
                                Пользователя,
                                содержащую информацию,
                                распространение которой нарушает нормы действующего законодательства
                                РФ;&nbsp; &nbsp; &nbsp;</Typography>
                            <Typography variant="body2" gutterBottom>5.5.2. Отзывы, сообщения и иные материалы
                                Пользователя,
                                содержащие неправомерно используемые
                                результаты интеллектуальной деятельности и средства индивидуализации;
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.5.3. Учётные записи (аккаунты) Пользователей,
                                нарушающих интеллектуальные права
                                Администратору, других Пользователей или третьих лиц.
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.6. Администратор предоставляет Пользователю
                                простую неисключительную лицензию на
                                право использования Сайта и произведений, размещаемых Администратором на сайте
                                посредством воспроизведения (копирования) и доведения до всеобщего сведения с
                                обязательным указанием источника в виде активной гиперссылки.
                            </Typography>
                            <Typography variant="body2" gutterBottom>5.7. Пользователь не вправе декомпилировать,
                                дизассемблировать, любым образом
                                модифицировать код элементов Сайта и/или необходимых для функционирования Сайта
                                сервисов.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>6. ВОЗНАГРАЖДЕНИЕ АВТОРОВ</b></Typography>
                            <Typography variant="body2" gutterBottom>6.1. Авторы Отзывов в праве запросить денежное
                                вознаграждение в случае составления и
                                выполнения всех условий дополнительного соглашения к данному Пользовательскому
                                соглашению и при выполнении всех условий отраженных на странице, находящихся по URL
                                адресу <Links href="https://ocenivay.com/rules">https://ocenivay.com/rules</Links>
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>7. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</b></Typography>
                            <Typography variant="body2" gutterBottom>7.1. Пользователь использует функционал Сайта на
                                свой страх и риск. Весь функционал
                                предоставляется «как есть» (as is) без каких-либо явных или подразумеваемых
                                гарантий.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.2. Пользователь гарантирует, что Отзывы являются
                                продуктом творческого труда Пользователя и не
                                содержат результатов интеллектуальной деятельности третьих лиц.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.3. Администратор не гарантирует, что
                                работоспособность Сайта будет осуществляться
                                непрерывно, без задержек, сбоев и вся предоставленная информация будет отображаться
                                достоверно. Сохранность пользовательской информации не гарантируется.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.4. Администратор не гарантирует доступность,
                                точность или достоверность сведений,
                                расположенных на Сайте или на сторонних ресурсах, ссылки на которые присутствуют на
                                Сайте.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.5. Администратор ни при каких обстоятельствах не
                                может нести ответственность за
                                содержание и негативные последствия использования (в том числе просмотра, сохранения
                                веб-страниц) сторонних ресурсов, ссылки на которые присутствуют на Сайте.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.6. Администратор не несёт ответственность перед
                                Пользователем за любой косвенный,
                                случайный, неумышленный ущерб, в том числе упущенную выгоду, утраченные данные, вред
                                чести, достоинству или деловой репутации, вызванный в связи с использованием Сайта,
                                содержимого Сайта или иных материалов, к которым Пользователь или иные лица получили
                                доступ с помощью Сайта.
                            </Typography>
                            <Typography variant="body2" gutterBottom>7.7. Администратор принимает все меры для
                                соблюдения прав на результаты
                                интеллектуальной деятельности третьих лиц, вместе с тем, поскольку Сайт допускает
                                наличие пользовательского контента, то оперативное удаление информации или
                                материалов, нарушающих интеллектуальные или иные права может быть
                                затруднено.</Typography>
                            <Typography variant="body2" gutterBottom>7.8. По требованию правообладателей результатов
                                интеллектуальной деятельности или
                                средств индивидуализации, неправомерно размещённых или иным образом использованных
                                на Сайте, а также по собственной инициативе при наличии сведений о нарушении
                                интеллектуальных прав, Администратор незамедлительно удаляет размещённые
                                Пользователями материалы, содержащие указанные объекты интеллектуальных прав.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>8. ИНЫЕ УСЛОВИЯ</b></Typography>
                            <Typography variant="body2" gutterBottom>8.1. Пользователь вправе:</Typography>
                            <Typography variant="body2" gutterBottom>8.1.1. Использовать Сайт и материалы в рамках
                                предоставленных настоящим соглашением
                                полномочий.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.1.2. Размещать на страницах Сайта информацию и
                                материалы, не противоречащую
                                условиям настоящего соглашения и законодательству Российской Федерации.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.2. Пользователю запрещено:</Typography>
                            <Typography variant="body2" gutterBottom>8.2.1. Вмешиваться в работу Сайта с целью нарушения
                                его нормального
                                функционирования.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.2.2. Осуществлять массовые рассылки сообщений
                                другим Пользователям.</Typography>
                            <Typography variant="body2" gutterBottom>8.2.3. Размещать на страницах Сайта информацию,
                                указанную в п. 4.4 настоящего
                                соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.2.4. Использовать Сайт и материалы, размещённые
                                на Сайте, вне предоставленных
                                настоящим соглашением полномочий или вне рамок функционала, для которого он
                                предназначен.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.2.5. Незаконно собирать и обрабатывать
                                персональные данные других Пользователей.
                            </Typography>
                            <Typography variant="body2" gutterBottom>8.2.6. Использовать размещённые на Сайте Отзывы, в
                                том числе, путём опубликования
                                Отзывов на других сайтах в информационно-телекоммуникационной сети «Интернет»,
                                предоставлять третьим лицам право использования Отзывов.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>9. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</b></Typography>
                            <Typography variant="body2" gutterBottom>9.1. Пользователь выражает свое согласие на
                                обработку Администратором (и
                                уполномоченными Администратором лицами) его персональных данных, предоставленных
                                Пользователем при регистрации, а также персональных данных, размещённых
                                самостоятельно Пользователем в любой части Сайта, включая поддомены.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.2. Пользователь выражает своё согласие на
                                обработку персональных данных в целях:
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.2.1. предоставления персональной
                                (таргетированной) рекламы Пользователю.</Typography>
                            <Typography variant="body2" gutterBottom>9.2.2. предоставления полной, точной и достоверной
                                информации об Отзывах
                                Пользователей.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.2.3. обеспечения возможности связи Пользователей
                                и разрешения спорных ситуаций
                                между поставщиками товаров / услуг и их потребителями.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.3. Обработка персональных данных Пользователя
                                осуществляется в соответствии с
                                действующим законодательством Российской Федерации и в целях выполнения обязательств
                                Администратора перед Пользователями в отношении использования Сайта и его
                                функционала.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.4. Публикуя в любом разделе Сайта информацию,
                                содержащую персональные данные
                                Пользователя, Пользователь выражает согласие на её обработку всеми способами,
                                предусмотренными действующим законодательством РФ (включая, но не ограничиваясь
                                такими способами как сбор, запись, систематизация, накопление, хранение, уточнение
                                (обновление, изменение), извлечение, использование, передача (распространение,
                                предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
                                персональных данных) Администратора, а также осознаёт, что указанная информация в
                                дальнейшем может быть распространена другими Пользователями, поисковыми системами,
                                автоматическими системами сбора данных и иными сервисами вне зависимости от воли
                                Администратора.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.5. Администратор вправе запросить у Пользователя
                                данные о его фамилии, имени и
                                отчестве, контактной информации, а также о месте работы исключительно для установления
                                личности
                                Пользователя и проверки достоверности представленной информации.
                            </Typography>
                            <Typography variant="body2" gutterBottom>9.6. Администратор принимает все возможные меры для
                                защиты персональных данных
                                Пользователя от неправомерного доступа третьих лиц.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>10. СОГЛАСИЕ НА ПОЛУЧЕНИЕ РЕКЛАМНЫХ
                                СООБЩЕНИЙ</b></Typography>
                            <Typography variant="body2" gutterBottom>10.1. Пользователь выражает своё согласие на
                                получение рекламных сообщений
                                посредством электронных сообщений на указанный Пользователем e-mail при регистрации.
                            </Typography>
                            <Typography variant="body2" gutterBottom>10.2. Пользователь вправе отказаться от получения
                                рекламных сообщений посредством
                                электронных сообщений.
                            </Typography><br/>

                            <Typography variant="body2" gutterBottom><b>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b></Typography>
                            <Typography variant="body2" gutterBottom>11.1. Признание судом отдельной части настоящего
                                соглашения недействительной или не
                                подлежащей исполнению не влечёт недействительности остальных положений Соглашения.
                            </Typography>
                            <Typography variant="body2" gutterBottom>11.2. Споры между сторонами подлежат разрешению в
                                претензионном порядке.</Typography>
                            <Typography variant="body2" gutterBottom>11.3. Срок ответа на претензию стороны не может
                                превышать 30 рабочих дней. При
                                отсутствии ответа в течение 30 рабочих дней, считая от первого рабочего дня,
                                следующего за датой направления претензии по Московскому времени, претензионный
                                порядок считается соблюдённым.
                            </Typography>
                            <Typography variant="body2" gutterBottom>11.4. В случае невозможности разрешения спора в
                                претензионном порядке, спор подлежит
                                рассмотрению в суде по месту нахождения Администратора.
                            </Typography>


                        </StyledPaper>

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    );

}

Offer.propTypes = {
    window: PropTypes.func,
};

export default Offer